import React from "react";
import { useTranslation } from 'react-i18next';
import '../i18n';

const Footer = () => {
    const { t } = useTranslation();

    return (
        <section>
            <div className="weight-footer-main-con bg-overly-img">
                <div className="container overlay-content">
                    <div className="weight-footer-item-con">
                        <div className="row wow fadeInUp">
                            <div className="col-lg-12 col-md-12 col-12 text-lg-left text-md-center">
                                <div className="weight-footer-item weight-footer-item1 mb-lg-0 mb-3">
                                    <div className="weight-footer-item-img">
                                        <figure>
                                            <img src={require('../img/logo-img.png')} alt="logo-img" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="weight-footer-item-content">
                                        <p className="mb-xl-0 mb-lg-0 mb-md-4 weight-footer-item1-content">{t('footer.description')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-con">
                    <div className="container overlay-content">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="footer-con text-center">
                                <p>Copyright Websecurity © 2022. {t('footer.rights')}</p>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Footer;