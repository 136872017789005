import React, { Component } from "react";
import WOW from 'wowjs';
import Footer from "./components/Footer";
import Form from "./components/Form";

// Components
import Header from "./components/Header";
import Information from "./components/Information";
import Security from "./components/Security";
import Service from "./components/Service";
import Skills from "./components/Skills";
import Slider from "./components/Slider";

class App extends Component {

  componentDidMount() {
    new WOW.WOW({
        live: false
    }).init();
  }

  render() {
    return (
      <div className="App">
        <Header />
        <Slider />
        <Information />
        <Skills />
        <Service />
        <Security />
        <Form />
        <Footer />
      </div>
    );
  }
}

export default App;
