import React from "react";
import { useTranslation } from 'react-i18next';
import Button from "./elements/Button";
import Navbar from "./elements/Navbar";
import '../i18n';

const Header = () => {
  const { t } = useTranslation();

  return (
    <div id="#home" className="header-and-banner-con w-100">
      <div className="header-and-banner-inner-con overlay-content">
          <header>
            {/* <!--navbar-start--> */}
            <div className="container">
                <div className="header-con">
                  <Navbar />
                </div>
            </div>
            {/* <!--navbar-end--> */}
          </header>
          <section className="banner-main-con">
            <div className="container">
                {/* <!--banner-start--> */}
                <div className="banner-con">
                  <div className="row">
                      <div className="col-lg-7 col-md-7 col-sm-12 d-flex justify-content-center flex-column banner-main-left-con">
                        <div className="banner-left-con wow slideInLeft">
                            <div className="banner-heading">
                              <h1>{t('header.title')}</h1>
                            </div>
                            <div className="banner-content">
                              <p className="col-lg-11 pl-0 pr-0">{t('header.topic1')}</p>
                              <p className="col-lg-11 pl-0 pr-0">{t('header.topic2')}</p>
                            </div>
                            <div className="banner-btn">
                              <Button url="#contact" class="contact-btn" content={t('navbar.contact')} />
                            </div>
                        </div>
                      </div>
                      <div className="col-lg-5 col-md-5 col-sm-12">
                        <div className="banner-right-con wow slideInRight"> 
                            <figure className="mb-0">
                              <img src={require('../img/banner-right-img.png')} alt="banner-right-img" />
                            </figure>
                        </div>
                      </div>
                  </div>
                </div>
                {/* <!--banner-end--> */}
            </div>
          </section>
      </div>
    </div>
  )
}

export default Header;