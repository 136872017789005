import React from "react";
import { useTranslation } from 'react-i18next';
import '../i18n';
// import CircularBar from "./elements/CircularBar";

const Service = () => {
    const { t } = useTranslation();

    return (
        <div className="service-main-con">
            <div className="row ml-0 mr-0">
                <div className="col-lg-6 padd-right-0">
                    <div className="service-main-left-top-con dots-right-img">
                        <div className="row">
                            <div className="col-xl-7 col-lg-12 col-sm-12">
                                <div className="service-main-left-con wow slideInLeft" style={{"visibility": "visible", "animationName": "slideInLeft"}}>
                                    <h5>{t('service.title')}</h5>
                                    <h2>{t('service.topic1')}</h2>
                                    <p>{t('service.topic2')}</p>
                                    <p>{t('service.topic3')}</p>
                                    {/* <div className="row service-skill-sttaf-con">

                                        <div className="col-lg-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div className="circle-wrap firstPercentage">
                                                <CircularBar percentage={85} textSize='26px' stroke={1} />
                                            </div>
                                            <div className="service-skill-sttaf-item-heading">
                                                <h3 className="mb-0 text-black">Ransomware</h3>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div className="circle-wrap secondPercentage">
                                                <CircularBar percentage={77} textSize='26px' stroke={1} />
                                            </div>
                                            <div className="service-skill-sttaf-item-heading">
                                                <h3 className="mb-0 text-black">Pishing</h3>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                            <div className="circle-wrap thirdPercentage">
                                                <CircularBar percentage={64} textSize='26px' stroke={1} />
                                            </div>
                                            <div className="service-skill-sttaf-item-heading">
                                                <h3 className="mb-0 text-black">Support Team</h3>
                                            </div>
                                        </div>
                                        
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-sm-12 padd-left-0">
                    <div className="service-main-right-con"></div>
                </div>
                
                <div className="service-box-content wow slideInRight" style={{"visibility": "visible", "animationName": "slideInRight"}}>
                    <figure>
                        <img src={require('../img/service-box-img.png')} alt="service-box-img" className="img-fluid" />
                    </figure>
                    <h6>{t('service.info1')}</h6>
                    <p>{t('service.info2')}</p>
                </div>
            </div>
        </div>
    )
}

export default Service;