import React from "react";
import { useTranslation } from 'react-i18next';
import '../i18n';

const Information = () => {
  const { t } = useTranslation();

  return (
    <section>
        <div className="informationmain-con dots-left-img w-100">
          <div className="container overlay-content">
              <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12 informationmain-left-con">
                      <div className="informationmainleft-sec-img wow slideInLeft">
                        <figure className="mb-0">
                            <img src={require('../img/informationmain-left-sec-img.png')} alt="informationmain-left-sec-img" />
                        </figure>
                      </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 informationmain-right-con wow slideInRight">
                    <div className="informationmain-right-heading">
                      <h5>{t('information.title')}</h5>
                      <h2>{t('information.topic1')}</h2>
                    </div>
                    <div className="informationmain-right-content">
                    <p>{t('information.topic2')}</p>
                    <ul className="mb-0 list">
                        <li><i className="fas fa-check-circle"></i>{t('information.bullet1')}</li>
                        <li><i className="fas fa-check-circle"></i>{t('information.bullet2')}</li>
                        <li><i className="fas fa-check-circle"></i>{t('information.bullet3')}</li>
                        <li><i className="fas fa-check-circle"></i>{t('information.bullet4')}</li>
                        <li><i className="fas fa-check-circle"></i>{t('information.bullet5')}</li>
                    </ul>
                    </div>
                  </div>
              </div>
          </div>
        </div>
    </section>
  )
}

export default Information;