import React, { Component } from "react";
import { withTranslation } from 'react-i18next';
import '../i18n';
import validator from "validator";
import Reaptcha from 'reaptcha';
import Constants from "./common/Constants";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Form extends Component<withTranslation> {

    constructor(props) {
        super(props);
        this.state = {
            verified: false,
            form: {
                "name": "",
                "email": "",
                "phone": "",
                "comments": ""
            }
        };

        // Set who is "this"
        this.handleUpdate = this.handleUpdate.bind(this);
        this.sendForm = this.sendForm.bind(this);
        this.notification = this.notification.bind(this);
    }

    render() {
      return (
        <section id="contact">
            <div className="form-main-con dots-left-img">
                <div className="container overlay-content">

                    <div className="row">
                        <div className="col-lg-4 wow slideInLeft">
                            <div className="weight-footer-item mb-lg-0 mb-md-0 mb-3">
                                <div className="weight-footer-item-title">
                                    <h3>{this.props.t('form.title')}</h3>
                                </div>
                                <div className="weight-footer-item-content weight-footer-item-link">
                                    <p>{this.props.t('form.topic1')}<br />{this.props.t('form.topic2')}</p>
                                    {/* <ul className="list-unstyled mb-0 social-icon-list">
                                        <li className="weight-footer-item2-content col-lg-11 pl-0 pr-0">121 King Street Melbourne, 3000,  Australia</li>
                                        <li><a href="mailto:info@Cyberchip.com">info@websecurity.center</a></li>
                                        <li><a href="tel:+123456789">+1 23 45 6789</a></li>
                                        <li className="d-inline-block mb-0"><a href="https://www.facebook.com/"><i className="fab fa-facebook-f"></i></a></li>
                                        <li className="d-inline-block mb-0"><a href="https://twitter.com/?lang=en"><i className="fab fa-twitter"></i></a></li>
                                        <li className="d-inline-block mb-0"><a href="https://www.instagram.com/accounts/login/"><i className="fab fa-instagram"></i></a></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-8">
                            <div className="form-title-con text-center wow slideInRight">
                                <h5>{this.props.t('form.title')}</h5>
                                <h2>{this.props.t('form.topic3')}</h2>
                            </div>
                            <ToastContainer />
                            <form className="form-inner-con wow slideInRight" id="contactpage" onSubmit={this.sendForm}>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-12">
                                        <input type="text" placeholder={this.props.t('form.pholder.name')} name="name" id="name" onChange={this.handleUpdate} value={this.state.form.name} /> 
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                    <input type="text" placeholder={this.props.t('form.pholder.email')} name="email" id="email" onChange={this.handleUpdate} value={this.state.form.email} /> 
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-12">
                                    <input type="phone" placeholder={this.props.t('form.pholder.phone')} name="phone" id="phone" onChange={this.handleUpdate} value={this.state.form.phone} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <textarea className="w-100" placeholder={this.props.t('form.pholder.message')} rows="3" name="comments" id="comments" onChange={this.handleUpdate} value={this.state.form.comments}></textarea>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 text-center">
                                        <Reaptcha 
                                            ref={e => (this.captcha = e)}
                                            sitekey={Constants.RECAPTCHA_SITE_KEY}  
                                            onVerify={this.onVerify}
                                            hl={this.props.t('lang')}
                                        />
                                    </div>
                                    <div className="col-lg-12 text-center">
                                        <button className="contact-btn">{this.props.t('form.send')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </section>
      )
    }

    /*
    * Recaptcha response
    */
    onVerify = recaptchaResponse => {
        this.setState({
          verified: true
        });
    };

    /*
    * Send form
    */
    async sendForm(event) 
    {
        event.preventDefault();

        // validations for empty fields
        if(true === validator.isEmpty(this.state.form.name))
        {
            this.notification('error', this.props.t('form.validation.name'));
            return false;
        }
        if(true === validator.isEmpty(this.state.form.email))
        {
            this.notification('error', this.props.t('form.validation.email'));
            return false;
        }
        if(true === validator.isEmpty(this.state.form.phone))
        {
            this.notification('error', this.props.t('form.validation.phone'));
            return false;
        }
        if(true === validator.isEmpty(this.state.form.comments))
        {
            this.notification('error', this.props.t('form.validation.comments'));
            return false;
        }

        // validations for email
        if (false === validator.isEmail(this.state.form.email))
        {
            this.notification('error', this.props.t('form.validation.email2'));
            return false;
        }

        // validations for phone
        if (false === validator.isMobilePhone(this.state.form.phone))
        {
            this.notification('error', this.props.t('form.validation.phone2'));
            return false;
        }

        // validations for recaptcha
        if (false === this.state.verified)
        {
            this.notification('error', this.props.t('form.validation.captcha'));
            return false;
        }

        // reset form and reCaptcha
        this.setState({
            form: {
                name: "",
                email: "",
                phone: "",
                comments: ""
            }
        });

        this.notification('success', this.props.t('form.validation.success'));
        this.captcha.reset();

        // convert to JSON
        var form        = this.state.form;
        form['class']   = 'Mail';
        form['method']  = 'form';
        const data = JSON.stringify(form);

        // send message
        await fetch(`${Constants.API_PRODUCTION_PATH}`, {
            method: "POST",
            body: data
        });
    }

    /*
    * Notifications with Toasterß
    */
    notification(type, message)
    {
        var options = {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        };

        type === 'success' ? toast.success(message, options) : toast.error(message, options);
    }
    
    /*
    * Manipulate form fields
    */
    handleUpdate(event) 
    {
        // Extrct key and value from state to update
        const key = event.target.id;
        let value = event.target.value;
        this.setState(state => {
            const formUpdated = state.form;
            // update form value. only in field that has been updated
            formUpdated[key] = value;
            return {
                form: formUpdated,
            }
        });
    }
  }

export default withTranslation() (Form);