import React from "react";
import { useTranslation } from 'react-i18next';
import '../i18n';

const Skills = () => {
    const skills = [
        {id:1, img: 'img1', title: 'skills.title1', description: 'skills.description1', css: 'Effective-sec-item mb-lg-0 mb-4 Effective-min-height'},
        {id:2, img: 'img2', title: 'skills.title2', description: 'skills.description2', css: 'Effective-sec-item mb-lg-0 mb-4 Effective-min-height'},
        {id:3, img: 'img3', title: 'skills.title3', description: 'skills.description3', css: 'Effective-sec-item Effective-sec-item3 Effective-min-height'},
        {id:4, img: 'img4', title: 'skills.title4', description: 'skills.description4', css: 'Effective-sec-item Effective-min-height'}
    ];

    const { t } = useTranslation();

    return (
        <section id="us">
            <div className="Effective-con bg-overly-img w-100">
                <div className="container overlay-content">
                    <div className="Effective-sec-heading text-center">
                        <h5>{t('skills.title')}</h5>
                        <h2>{t('skills.topic1')}</h2>
                    </div>
                    <div className="row wow fadeInUp">
                        {
                            skills.map(e =>
                                <div key={e.id} className="col-lg-3 col-md-6 col-12">
                                    <div className={e.css}>
                                        <figure>
                                            <img src={require('../img/Effective-sec-item-' + e.img + '.png')} alt="Effective-sec-item-img" className="img-fluid" />
                                        </figure>
                                        <div className="Effective-sec-item-title">
                                            <h4 className="mb-0">{t(e.title)}</h4>
                                            <p className="mb-0">{t(e.description)}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Skills;