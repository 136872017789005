import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import "../../css/custom.css";

export default function Navbar() {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const handleLangChange = evt => {
      const lang = evt.target.value;
      setLanguage(lang);
      i18n.changeLanguage(lang);
    };

    const languages = [
        { id: 1, value: 'es', label: 'Español' },
        { id: 2, value: 'en', label: 'English' }
      ];

    const [toggleMenu, setToggleMenu] = useState(false);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const toggleNav = () => {
        setToggleMenu(!toggleMenu)
    }

    useEffect(() => {
        const changeWidth = () => {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth)
        return () => {
            window.removeEventListener('resize', changeWidth)
        }
    }, [])

    return (
        <div className="d-flex">
            <div className="col-lg-3 col-sm-12">
                <img src={require('../../img/logo-img.png')} alt="logo-img" className="img-fluid" />
            </div>

            <div className="col-lg-4 mt-3 col-sm-12">
                <select onChange={handleLangChange} value={language} className="form-select form-select-sm ml-5 nav-language">
                    {
                        languages.map(e => 
                            <option key={e.id} value={e.value}>{e.label}</option> 
                        )
                    }
                </select>
            </div>
            
            <div className="col-lg-5 col-sm-12 nav-container">
                {(toggleMenu || screenWidth > 576) && (
                    <ul className="nav-list">
                        <li className="items">
                            <a href="#home">Home</a>
                        </li>
                        <li className="items">
                            <a href="#services">{t('navbar.services')}</a>
                        </li>
                        <li className="items">
                            <a href="#us">{t('navbar.us')}</a>
                        </li>
                        <li className="items">
                            <a href="#stats">{t('navbar.stats')}</a>
                        </li>
                        <li className="items contact-btn m-0 p-3">
                            <a href="#contact">{t('navbar.contact')}</a>
                        </li>
                    </ul>
                )}
            </div>

            <button onClick={toggleNav} className="btn" type="button">
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
                <span className="navbar-toggler-icon"></span>
            </button>
        </div>
    );
}