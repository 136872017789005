import React from "react";
import { useTranslation } from 'react-i18next';
import '../i18n';
import Button from "./elements/Button";

const Security =() => {
    const { t } = useTranslation();

    return (
        <section id="stats">
            <div className="Cyber-Security-con bg-overly-img w-100">
                <div className="container overlay-content">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 Cyber-Security-left-con wow slideInLeft">
                            <div className="row Cyber-Security-left-main-con">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                                    <div className="Cyber-Security-left-card-con">
                                        <div className="Cyber-Security-left-card-img">
                                            <figure>
                                                <img src={require('../img/Cyber-Security-left-card-img1.png')} alt="Cyber-Security-left-card-img" className="img-fluid" />
                                            </figure>
                                        </div>
                                        <div className="Cyber-Security-left-card-title">
                                            <h3 className="count">{t('security.item.title1')}</h3>
                                            <p className="mb-0">{t('security.item.description1')}</p>
                                            <p className="mb-0"><a href="https://cybersecurityventures.com/global-ransomware-damage-costs-predicted-to-reach-250-billion-usd-by-2031/" rel="noreferrer" target="_blank">{t('security.source')}</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                                    <div className="Cyber-Security-left-card-con">
                                        <div className="Cyber-Security-left-card-img">
                                            <figure>
                                                <img src={require('../img/Cyber-Security-left-card-img2.png')} alt="Cyber-Security-left-card-img" className="img-fluid" />
                                            </figure>
                                        </div>
                                        <div className="Cyber-Security-left-card-title">
                                            <h3 className="count">{t('security.item.title2')}</h3>
                                            <p className="mb-0">{t('security.item.description2')}</p>
                                            <p className="mb-0"><a href="https://betanews.com/2021/12/20/cybercriminals-penetrate-93-percent-of-company-networks/" rel="noreferrer" target="_blank">{t('security.source')}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                                    <div className="Cyber-Security-left-card-con">
                                        <div className="Cyber-Security-left-card-img">
                                            <figure>
                                                <img src={require('../img/Cyber-Security-left-card-img3.png')} alt="Cyber-Security-left-card-img" className="img-fluid" />
                                            </figure>
                                        </div>
                                        <div className="Cyber-Security-left-card-title">
                                            <h3 className="count d-inline-block">{t('security.item.title3')}</h3>
                                            <p className="mb-0">{t('security.item.description3')}</p>
                                            <p className="mb-0"><a href="https://www.infobae.com/america/tecno/2022/09/16/pequenas-y-medianas-empresas-tambien-estan-siendo-victimas-de-ciberataques/" rel="noreferrer" target="_blank">{t('security.source')}</a></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                                    <div className="Cyber-Security-left-card-con mb-0">
                                        <div className="Cyber-Security-left-card-img">
                                            <figure>
                                            <img src={require('../img/Cyber-Security-left-card-img4.png')} alt="Cyber-Security-left-card-img" className="img-fluid" />
                                            </figure>
                                        </div>
                                        <div className="Cyber-Security-left-card-title position-relative d-inline-block">
                                            <h3 className="count">{t('security.item.title4')}</h3>
                                            <p className="mb-0">{t('security.item.description4')}</p>
                                            <p className="mb-0"><a href="https://cybersecurity-magazine.com/10-small-business-cyber-security-statistics-that-you-should-know-and-how-to-improve-them/" rel="noreferrer" target="_blank">{t('security.source')}</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 Cyber-Security-right-con wow slideInRight">
                            <div className="Cyber-Security-right-heading-con">
                            <h5>{t('security.title')}</h5>
                            <h2>{t('security.topic1')}</h2>
                            <p>{t('security.topic2')}</p>
                            <Button url="#contact" class="contact-btn" content={t('security.contact')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Security;