import React from "react";
import { useTranslation } from 'react-i18next';
import '../i18n';

const Slider = () => {
   const { t } = useTranslation();
   const slides = [
            {id:1, img: 'img1', txt: 'slider.slide1'},
            {id:2, img: 'img2', txt: 'slider.slide2'},
            {id:3, img: 'img3', txt: 'slider.slide3'},
            {id:4, img: 'img4', txt: 'slider.slide4'},
            {id:5, img: 'img5', txt: 'slider.slide5'},
            {id:6, img: 'img6', txt: 'slider.slide6'}
         ];

   return (
      <section id="services">
         <div className="slider-con w-100">
            <div className="container">
               <div className="slider-heading text-center">
                  <h5>{t('slider.title')}</h5>
                  <h2>{t('slider.topic1')}</h2>
               </div>
               <div className="row wow fadeInUp">
                  {
                     slides.map(e =>
                        <div key={e.id} className="col-lg-2 col-md-4 col-6">
                           <div className="partner-box text-center mb-lg-0 mb-4">
                              <figure className="mb-0">
                                 <img src={require('../img/slider-item-' + e.img + '.png')} alt="" className="img-fluid" />
                                 <div className="col-lg-12 mt-3">{t(e.txt)}</div>
                              </figure>
                           </div>
                        </div>
                     )
                  }
               </div>
            </div>
         </div>
      </section>
   )
}

export default Slider;